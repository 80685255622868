import React, { Component } from 'react';
import MainMenu from '../MainMenu/index';
import HeaderLangSwitcher from './components/HeaderLangSwitcher';

import logo from './static/img/logo.svg';

import {
  HeaderBlock,
  HeaderWrapper,
  Logo,
  Interactive,
  MenuButton
} from './styles';

class Header extends Component {
  state = {
    isScrolled: false
  };

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.handleScroll);
    }
    this.handleScroll();
  }

  componentWillMount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleScroll = () => {
    const isScrolled = window.scrollY > 40;
    this.setState({ isScrolled });
  };

  render() {

    return (
      <HeaderBlock scrolled={this.state.isScrolled}>
        <HeaderWrapper>
          <Logo>
            <img src={logo} alt="United Traders Token logo" />
          </Logo>
          <MainMenu header={true} />
          <Interactive>
            <HeaderLangSwitcher />
            <MenuButton onClick={this.props.onSideMenu}>Open Menu</MenuButton>
          </Interactive>
        </HeaderWrapper>
      </HeaderBlock>
    );
  }
}

export default Header;
