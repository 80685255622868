import styled from 'react-emotion';

import fb from './static/img/fb.svg';
import fbHover from './static/img/fb-hover.svg';
import fbWhite from './static/img/fb-white.svg';
import gh from './static/img/gh.svg';
import ghHover from './static/img/gh-hover.svg';
import ghWhite from './static/img/gh-white.svg';
import medium from './static/img/medium.svg';
import mediumHover from './static/img/medium-hover.svg';
import mediumWhite from './static/img/medium-white.svg';
import tg from './static/img/tg.svg';
import tgHover from './static/img/tg-hover.svg';
import tgWhite from './static/img/tg-white.svg';
import tw from './static/img/tw.svg';
import twHover from './static/img/tw-hover.svg';
import twWhite from './static/img/tw-white.svg';
import utmag from './static/img/utmag.svg';
import utmagHover from './static/img/utmag-hover.svg';
import utmagWhite from './static/img/utmag-white.svg';
import yt from './static/img/yt.svg';
import ytHover from './static/img/yt-hover.svg';
import ytWhite from './static/img/yt-white.svg';

export const SocialList = styled('ul')`
  display: flex;
  justify-content: space-between;
  margin: 0 auto 9.4rem;
  padding: 0;
  width: 65rem;
  height: 7.1rem;

  @media (max-width: 768px) {
    margin: 0 0 6.5rem 0;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
  }

  li {
    display: flex;
    list-style: none;

    @media (max-width: 768px) {
      margin: 0 0 1px 0;
      width: 49.8%;
      height: 5rem;
    }
  }
`;

export const SocialLink = styled('a')`
  position: relative;
  display: flex;
  margin-top: auto;
  min-height: 7.1rem;
  text-decoration: none;
  text-align: center;
  color: ${props => {
    if (props.style.isUtmag) {
      return '#0390cb';
    } else if (props.style.isTelegram) {
      return '#198dc6';
    } else if (props.style.isFacebook) {
      return '#335baa';
    } else if (props.style.isTwitter) {
      return '#2e87cc';
    } else if (props.style.isMedium) {
      return '#019870';
    } else if (props.style.isYoutube) {
      return '#f50000';
    } else if (props.style.isGithub) {
      return '#6e32da';
    }
    return '';
  }};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4.5rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url(${props => {
      if (props.style.isUtmag) {
        return utmag;
      } else if (props.style.isTelegram) {
        return tg;
      } else if (props.style.isFacebook) {
        return fb;
      } else if (props.style.isTwitter) {
        return tw;
      } else if (props.style.isMedium) {
        return medium;
      } else if (props.style.isYoutube) {
        return yt;
      } else if (props.style.isGithub) {
        return gh;
      }
      return '';
    }});

    @media (max-width: 768px) {
      top: 1rem;
      left: 2.6rem;
      width: 2rem;
      height: 2.7rem;
      background-image: url(${props => {
        if (props.style.isUtmag) {
          return utmagWhite;
        } else if (props.style.isTelegram) {
          return tgWhite;
        } else if (props.style.isFacebook) {
          return fbWhite;
        } else if (props.style.isTwitter) {
          return twWhite;
        } else if (props.style.isMedium) {
          return mediumWhite;
        } else if (props.style.isYoutube) {
          return ytWhite;
        } else if (props.style.isGithub) {
          return ghWhite;
        }
        return '';
      }});
    }
  }

  &:hover,
  &:focus {
    color: #001cba;

    &::before {
      background-image: url(${props => {
        if (props.style.isUtmag) {
          return utmagHover;
        } else if (props.style.isTelegram) {
          return tgHover;
        } else if (props.style.isFacebook) {
          return fbHover;
        } else if (props.style.isTwitter) {
          return twHover;
        } else if (props.style.isMedium) {
          return mediumHover;
        } else if (props.style.isYoutube) {
          return ytHover;
        } else if (props.style.isGithub) {
          return ghHover;
        }
        return '';
      }});
    }
  }

  @media (max-width: 768px) {
    flex-direction: row;
    padding: 1.3rem 2.7rem 1.3rem 6.3rem;
    justify-content: flex-start;
    width: 100%;
    min-height: 100%;
    color: #fff;
    text-align: left;
    background-color: ${props => {
      if (props.style.isUtmag) {
        return '#0390cb';
      } else if (props.style.isTelegram) {
        return '#198dc6';
      } else if (props.style.isFacebook) {
        return '#335baa';
      } else if (props.style.isTwitter) {
        return '#2e87cc';
      } else if (props.style.isMedium) {
        return '#019870';
      } else if (props.style.isYoutube) {
        return '#f50000';
      } else if (props.style.isGithub) {
        return '#6e32da';
      }
      return '';
    }};

    &:hover,
    &:focus {
      color: #fff;
      background-color: #001cba;

      &::before {
        background-image: url(${props => {
          if (props.style.isUtmag) {
            return utmagWhite;
          } else if (props.style.isTelegram) {
            return tgWhite;
          } else if (props.style.isFacebook) {
            return fbWhite;
          } else if (props.style.isTwitter) {
            return twWhite;
          } else if (props.style.isMedium) {
            return mediumWhite;
          } else if (props.style.isYoutube) {
            return ytWhite;
          } else if (props.style.isGithub) {
            return ghWhite;
          }
          return '';
        }});
      }
    }
  }
`;

export const SocialName = styled('span')`
  margin-top: auto;

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;
