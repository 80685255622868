import React, { Component } from 'react';

import { SocialList, SocialLink, SocialName } from './styles';

class SocialLinks extends Component {
  render() {
    return (
      <SocialList>
        <li>
          <SocialLink
            style={{ isUtmag: true }}
            href="https://media.unitedtraders.com/category/utex"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialName>UT Media</SocialName>
          </SocialLink>
        </li>
        <li>
          <SocialLink
            style={{ isTelegram: true }}
            href="https://t.me/uttoken"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialName>Telegram</SocialName>
          </SocialLink>
        </li>
        <li>
          <SocialLink
            style={{ isFacebook: true }}
            href="https://www.facebook.com/unitedtraders/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialName>Facebook</SocialName>
          </SocialLink>
        </li>
        <li>
          <SocialLink
            style={{ isYoutube: true }}
            href="https://www.youtube.com/user/theunitedtraders"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialName>Youtube</SocialName>
          </SocialLink>
        </li>
        <li>
          <SocialLink
            style={{ isGithub: true }}
            href="https://github.com/unitedtraders"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialName>Github</SocialName>
          </SocialLink>
        </li>
      </SocialList>
    );
  }
}

export default SocialLinks;
