import { Component } from 'react';
import React from 'react';
import Lightbox from 'react-images';

import { PastEventBlock, EventLabel, EventName, EventTime, EventPreview } from './styles';

class PastEvent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImage: 0,
      lightboxIsOpen: false,
      showAllIntention: false,
      width: -1,
      showAll: false
    };
  }

  openLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: true
    });
  };
  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false
    });
  };

  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1
    });
  };

  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  };

  render() {
    return (
      <PastEventBlock>
        <EventPreview onClick={this.openLightbox}>
          <img src={this.props.img} alt={this.props.name} />
        </EventPreview>
        <EventLabel>
          <EventName>{this.props.name}</EventName>
          <EventTime>{this.props.time}</EventTime>
        </EventLabel>
        <Lightbox
          images={this.props.images}
          onClose={this.closeLightbox}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          currentImage={this.state.currentImage}
          isOpen={this.state.lightboxIsOpen}
          backdropClosesModal
        />
      </PastEventBlock>
    );
  }
}

export default PastEvent;
