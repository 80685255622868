import styled from 'react-emotion';
import unseccessful from './static/icons/unseccessful.svg';

export const RoadMapBlock = styled('div')`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;

  @media (max-width: 1660px) and (min-width: 1200px) {
    margin: 0 3rem 3rem 3rem;
  }

  @media (max-width: 1200px) {
    margin-bottom: 2rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 9rem;
    width: 100%;
    height: 4px;
    background-color: #fff;

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const Back = styled('section')`
  display: flex;
  align-content: center;
  padding-top: 2rem;
  width: 79.5rem;
  background-color: #f5f5f5;
  margin-right: ${props => (props.first ? '3.1rem' : '0')};

  @media (max-width: 1200px) {
    margin-right: ${props => (props.first ? '2rem' : '0')};
  }

  @media (max-width: 768px) {
    margin: 0 0 1rem;
    width: 100%;
  }
`;

export const RoadMapWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-bottom: 1.6rem;

  @media (max-width: 768px) {
    padding: 2.8rem 1.6rem;
  }

  h3 {
    margin: 0 0 2.3rem;
    font-family: 'Dewi', Verdana, sans-serif;
    font-size: 2rem;
    font-weight: 700;
    line-height: 4rem;

    @media (max-width: 360px) {
      line-height: 3rem;
    }
  }
`;

export const EventsList = styled('section')`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  max-width: 60.5rem;

  @media (max-width: 768px) {
    height: auto;
  }
`;

export const RoadMapEvent = styled('div')`
  display: flex;
  position: relative;

  &:last-of-type {
    div {
      &::before {
        display: none;
      }
    }
  }

  &:nth-last-of-type(2) {
    flex-grow: 1;

    div {
      padding-bottom: 4rem;

      @media (max-width: 768px) {
        padding-bottom: 1.5rem;
      }
    }
  }
`;

export const Date = styled('span')`
  margin-right: 5.1rem;
  width: 12.6rem;
  font-size: 1.3rem;
  letter-spacing: 1.3px;
  line-height: 2rem;
  opacity: 0.4;
  text-transform: uppercase;

  @media (max-width: 768px) {
    width: 12.6rem;
    font-size: 1.1rem;
    letter-spacing: 1.1px;
    line-height: 1.8rem;
  }
`;

export const Event = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
  width: 41.6rem;

  @media (max-width: 905px) {
    width: 35rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: 1.3rem;
    line-height: 1.6rem;
  }

  &::after {
    content: '';
    position: absolute;
    left: -4rem;
    top: 0.4rem;
    width: 1.3rem;
    height: 1.3rem;
    border: ${props => props.successful && '1px solid #2f4efe'};
    border-radius: ${props => props.successful && '50%'};
    background-color: ${props => (props.passed && props.successful ? '#2f4efe' : '#F5F5F5')};
    background-image: url(${props => props.passed && !props.successful && unseccessful});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &::before {
    content: '';
    position: absolute;
    left: -3.4rem;
    top: 0.4rem;
    height: 100%;
    border-left: ${props =>
      props.passed && props.successful ? '1px solid #2f4efe' : '1px dotted #176CD4'};
  }
`;

export const EventDesc = styled('p')`
  margin: 0 0.8rem 0 0;
  align-self: ${props => (props.alignStart ? 'flex-start' : 'center')};
`;

export const Image = styled('img')`
  display: inline-block;
  max-height: 1.7rem;
  transform: ${props =>
    props.smаllImg ? 'translate(0.5rem, 0.3rem)' : 'translate(0.5rem, 0.4rem)'};

  @media (max-width: 768px) {
    margin-bottom: 0;
    max-height: 1.5rem;
    transform: ${props =>
      props.smаllImg ? 'translate(0.5rem, 0.3rem)' : 'translate(0.5rem, 0.3rem)'};
  }
`;

export const EventLink = styled('a')`
  margin-left: 0.5rem;
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  cursor: pointer;
`;
