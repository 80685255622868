import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/ru';

import { Element } from 'react-scroll';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Layout from '../components/Layout';
import Header from '../components/Header';
import Product from '../components/Product';
import RoadMap from '../components/RoadMap';
import Team from '../components/Team';
import About from '../components/About';
import Partners from '../components/Partners';
import Footer from '../components/Footer';
import Events from '../components/Events';
import SideMenu from '../components/SideMenu';
import { withI18next } from '@wapps/gatsby-plugin-i18next';
import { graphql } from 'gatsby';
import { withNamespaces } from 'react-i18next';

const CLOSE_ANIMATION_TIME = 500;

class IndexPage extends Component {
  state = {
    isSideMenuVisible: false,
    isStartClosing: false
  };

  constructor(props) {
    super();
    moment.locale(props.lng);
  }

  showSideMenu = () => {
    this.setState({ isSideMenuVisible: true, isStartClosing: true });
    disableBodyScroll(this.targetElement, { reserveScrollBarGap: true });
  };

  hideSideMenu = () => {
    this.setState({ isStartClosing: false });

    setTimeout(() => {
      this.setState({ isSideMenuVisible: false });
    }, CLOSE_ANIMATION_TIME);

    enableBodyScroll();
  };

  render() {
    return (
      <Layout lng={this.props.lng}>
        {this.state.isSideMenuVisible && (
          <SideMenu onClose={this.hideSideMenu} show={this.state.isStartClosing} />
        )}

        <Element name="header">
          <Header onSideMenu={this.showSideMenu} />
        </Element>
        <Element name="product">
          <Product />
        </Element>
        <Element name="roadmap">
          <RoadMap />
        </Element>
        <Element name="events">
          <Events />
        </Element>
        <Element name="about">
          <About />
        </Element>
        <Element name="partners">
          <Partners />
        </Element>
        <Footer />
      </Layout>
    );
  }
}

export default withI18next()(withNamespaces('main')(IndexPage));

export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "main" } }) {
      ...LocaleFragment
    }
  }
`;
