import React, { Component } from 'react';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import MainMenu from '../MainMenu';
import SideMenuLinks from './components/SideMenuLinks';
import SideMenuLangSwitcher from './components/SideMenuLangSwitcher';

import closeImg from './static/img/close.svg';

import { SideMenuBlock, CloseButton, OverlaySideMenu } from './styles';

class SideMenu extends Component {
  staySideMenu = e => {
    e.stopPropagation();
  };

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  render() {
    return (
      <OverlaySideMenu onClick={this.props.onClose} isShow={this.props.show}>
        <SideMenuBlock onClick={this.staySideMenu} isShow={this.props.show}>
          <CloseButton onClick={this.props.onClose}>
            <img src={closeImg} alt="close menu" />
          </CloseButton>
          <MainMenu />
          <SideMenuLinks />
          <SideMenuLangSwitcher />
        </SideMenuBlock>
      </OverlaySideMenu>
    );
  }
}

export default SideMenu;
