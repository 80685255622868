import React, { Component } from 'react';
import { withI18n } from 'react-i18next';
import PastEvent from './components/PastEvent';

import albums from './albums';

import { EventsBlock, PastEvents, EventsLineWrapper, PastEventsWrapper } from './styles';

class Events extends Component {
  render() {
    const { t } = this.props;

    return (
      <EventsBlock>
        <PastEvents>
          <h2>{t('Recent Events')}</h2>
          <PastEventsWrapper>
            <EventsLineWrapper>
              <PastEvent
                img={'https://investment-prod.s3.amazonaws.com/utex/events/11_05.jpg'}
                name={t('Russian Tech Week 2018 & Crypto Event RIW')}
                time={t('Nov 22 2018')}
                images={albums.utBlockchainLife2}
              />
              <PastEvent
                img={'https://investment-prod.s3.amazonaws.com/utex/events/10_4.jpg'}
                name={t('UTEX team at Blockchain Life 2018')}
                time={t('Nov 5 2018')}
                images={albums.utBlockchainLife}
              />
              <PastEvent
                img={'https://investment-prod.s3.amazonaws.com/utex/events/9-02.jpg'}
                name={t('UTEX Team at Futurama Blockchain Summit')}
                time={t('Sep 10 2018')}
                images={albums.utFuturama}
              />
            </EventsLineWrapper>
            <EventsLineWrapper>
              <PastEvent
                img={'https://investment-prod.s3.amazonaws.com/utex/events/8-1.jpg'}
                name={t('UTEX Team at Blockchain Cruise')}
                time={t('Europe Sep 7 2018')}
                images={albums.utBCCruise}
              />
              <PastEvent
                img={'https://investment-prod.s3.amazonaws.com/utex/events/7-1.jpg'}
                name={t('Anatoly Radchenko at BlockShow Americas 2018')}
                time={t('Las Vegas Mar 6 2018')}
                images={albums.utVegas}
              />
              <PastEvent
                img={'https://investment-prod.s3.amazonaws.com/utex/events/6-1.jpg'}
                name={'Futurama Blockchain Summit'}
                time={t('Dubai Mar 6 2018')}
                images={albums.utHongKong2}
              />
            </EventsLineWrapper>
            <EventsLineWrapper>
              <PastEvent
                img={'https://investment-prod.s3.amazonaws.com/utex/events/5-1.jpg'}
                name={t('Anatoly Radchenko at Russian Blockchain Week')}
                time={t('Moscow May 21 2018')}
                images={albums.utRBW}
              />
              <PastEvent
                img={'https://investment-prod.s3.amazonaws.com/utex/events/4-1.jpg'}
                name={t('Roman Vishnevskiy at Block 020 Blockchain Summit')}
                time={t('Hong Kong May 8 2018')}
                images={albums.utHongKong}
              />
              <PastEvent
                img={'https://investment-prod.s3.amazonaws.com/utex/events/3-1.jpg'}
                name={t('Anatoly Radchenko at Global Blockchain Forum')}
                time={t('Moscow Feb 21 2018')}
                images={albums.utFintech}
              />
            </EventsLineWrapper>
            <EventsLineWrapper>
              <PastEvent
                img={'https://investment-prod.s3.amazonaws.com/utex/events/2-1.jpg'}
                name={'Blockshow Asia'}
                time={t('Singapore Nov 29 2017')}
                images={albums.utBlackshow}
              />
              <PastEvent
                img={'https://investment-prod.s3.amazonaws.com/utex/events/1-1.jpg'}
                name={'United Traders Conference'}
                time={t('Moscow 25 Nov 2017')}
                images={albums.utConference}
              />
            </EventsLineWrapper>
          </PastEventsWrapper>
        </PastEvents>
      </EventsBlock>
    );
  }
}

export default withI18n()(Events);
