import React, { Component } from 'react';
import Helmet from 'react-helmet';

import favIcon from './img/favicon.png';

import './styles/styles.scss';

class Layout extends Component {
  render() {
    return (
      <div>
        <Helmet
          title="United Traders Token"
          meta={[
            { name: 'description', content: '' },
            { name: 'keywords', content: '' },
            {
              name: 'viewport',
              content: 'width=device-width, initial-scale=1.0'
            }
          ]}
          link={[{ rel: 'shortcut icon', href: favIcon }]}
        />
        <div className="container">{this.props.children}</div>
      </div>
    );
  }
}

export default Layout;
