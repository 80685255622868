import React, { Component } from 'react';
import { withI18n } from 'react-i18next';

import SocialLinks from './components/SocialLinks/index';

import { FooterWrapper, InfoWrapper, Contacts, LicenseInfo } from './styles';

class Footer extends Component {
  render() {
    const { t } = this.props;

    return (
      <FooterWrapper>
        <h2>{t('Follow us and stay tuned')}</h2>
        <SocialLinks />

        <InfoWrapper>
          <Contacts>
            <div>
              <a href="callto:+74956461557" target="_blank" rel="noopener noreferrer">
                +7 495 646-15-57
              </a>
              <a href="callto:88003336681" target="_blank" rel="noopener noreferrer">
                8 800 333-66-81
              </a>
            </div>
            <a href="mailto:welcome@unitedtraders.com" target="_blank" rel="noopener noreferrer">
              welcome@unitedtraders.com
            </a>
          </Contacts>

          <LicenseInfo>
            <a href="https://unitedtraders.com/" target="_blank" rel="noopener noreferrer">
              © United Traders
            </a>
            <span> 2018</span>
          </LicenseInfo>
        </InfoWrapper>
      </FooterWrapper>
    );
  }
}

export default withI18n()(Footer);
