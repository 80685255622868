import Vishnevskij from './static/img/team-01/Vishnevsky.jpg';
import Vishnevskij_x2 from './static/img/team-01/Vishnevsky@2x.jpg';
import Radchenko from './static/img/team-01/Radchenko.jpg';
import Radchenko_x2 from './static/img/team-01/Radchenko@2x.jpg';
import BelousovD from './static/img/team-01/Belousov-d.jpg';
import BelousovD_x2 from './static/img/team-01/Belousov-d@2x.jpg';
import Kurdyukov from './static/img/team-01/Kurdyukov.jpg';
import Kurdyukov_x2 from './static/img/team-01/Kurdyukov@2x.jpg';
import BelousovE from './static/img/team-01/Belousov-e.jpg';
import BelousovE_x2 from './static/img/team-01/Belousov-e@2x.jpg';
import Fadeev from './static/img/team-01/Fadeev.jpg';
import Fadeev_x2 from './static/img/team-01/Fadeev@2x.jpg';
import Novikova from './static/img/team-01/Novikova.jpg';
import Novikova_x2 from './static/img/team-01/Novikova@2x.jpg';
import Markov from './static/img/team-01/Markov.jpg';
import Markov_x2 from './static/img/team-01/Markov@2x.jpg';
import Tsinker from './static/img/team-01/Tsinker.jpg';
import Tsinker_x2 from './static/img/team-01/Tsinker@2x.jpg';
import Trushkina from './static/img/team-01/Trushkina.jpg';
import Trushkina_x2 from './static/img/team-01/Trushkina@2x.jpg';
import Butin from './static/img/team-01/Butin.jpg';
import Butin_x2 from './static/img/team-01/Butin@2x.jpg';

export default function getTeam(t) {
  return {
    founders: [
      {
        name: t('Roman Vishnevskij'),
        img: Vishnevskij,
        img2: Vishnevskij_x2,
        status: t('Managing Partner'),
        edu: t('Previously Swift Trade Higher School of Economics')
      },
      {
        name: t('Anatoly Radchenko'),
        img: Radchenko,
        img2: Radchenko_x2,
        status: t('Managing Partner'),
        edu: t('Previously Dimension Trading ITMO University')
      },
      {
        name: t('Dmitry Belousov'),
        img: BelousovD,
        img2: BelousovD_x2,
        status: t('Managing Partner'),
        edu: t('Previously Swift Trade Bauman MSTU')
      },
      {
        name: t('Alexander Kurdyukov'),
        img: Kurdyukov,
        img2: Kurdyukov_x2,
        status: t('Chief Technical Officer'),
        edu: t('Previsously Aeroflot Alfa Bank Moscow State University')
      }
    ],
    managers: [
      {
        name: t('Evgeny Belousov'),
        img: BelousovE,
        img2: BelousovE_x2,
        status: t('Product Manager UTEX')
      },
      {
        name: t('Elena Novikova'),
        img: Novikova,
        img2: Novikova_x2,
        status: t('Creative Director')
      },
      {
        name: t('Denis Fadeev'),
        img: Fadeev,
        img2: Fadeev_x2,
        status: t('Design Director')
      },
      {
        name: t('Irina Trushkina'),
        img: Trushkina,
        img2: Trushkina_x2,
        status: t('Chief Financial Officer')
      },
      {
        name: t('Alexey Markov'),
        img: Markov,
        img2: Markov_x2,
        status: t('Business developer')
      },
      {
        name: t('Yaroslav Butin'),
        img: Butin,
        img2: Butin_x2,
        status: t('Head of UT Investment')
      },
      {
        name: t('Eduard Tsinker'),
        img: Tsinker,
        img2: Tsinker_x2,
        status: t('Director Affiliates Relations')
      }
    ]
  };
}
