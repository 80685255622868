import styled from 'react-emotion';

export const EventsBlock = styled('section')`
  padding: 4.8rem 0 8.3rem;
  margin-bottom: 3rem;
  background-color: #f5f5f5;

  @media (max-width: 1660px) and (min-width: 1200px) {
    margin: 0 3rem 3rem 3rem;
  }

  @media (max-width: 1200px) {
    margin-bottom: 2rem;
  }

  @media (max-width: 768px) {
    margin: 0 0 1rem;
    padding: 2.7rem 0 4rem;
  }

  h2 {
    margin: 0 auto 3rem;
    max-width: 120rem;
    font-family: 'Dewi', Verdana, sans-serif;
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 4rem;

    @media (max-width: 768px) {
      font-size: 2.4rem;
      text-align: center;
    }
  }
`;

export const PastEvents = styled('section')`
  margin: 0 auto 2.4rem;
  max-width: 120rem;
`;

export const PastEventsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const EventsLineWrapper = styled('div')`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
