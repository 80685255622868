import Klevtsov from './static/img/team-02/Klevtsov.jpg';
import Klevtsov_x2 from './static/img/team-02/Klevtsov@2x.jpg';
import Urumyan from './static/img/team-02/Urumyan.jpg';
import Urumyan_x2 from './static/img/team-02/Urumyan@2x.jpg';
import Nizev from './static/img/team-02/Nizev.jpg';
import Nizev_x2 from './static/img/team-02/Nizev@2x.jpg';
import Reznikov from './static/img/team-02/Reznikov.jpg';
import Reznikov_x2 from './static/img/team-02/Reznikov@2x.jpg';
import Khvatkov from './static/img/team-02/Khvatkov.jpg';
import Khvatkov_x2 from './static/img/team-02/Khvatkov@2x.jpg';
import Chemankov from './static/img/team-02/Chemankov.jpg';
import Chemankov_x2 from './static/img/team-02/Chemankov@2x.jpg';
import Evseev from './static/img/team-02/Evseev.jpg';
import Evseev_x2 from './static/img/team-02/Evseev@2x.jpg';
import Zulin from './static/img/team-02/Zhulin.jpg';
import Zulin_x2 from './static/img/team-02/Zhulin@2x.jpg';
import Rusko from './static/img/team-02/Rusko.jpg';
import Rusko_x2 from './static/img/team-02/Rusko@2x.jpg';
import Shubartsov from './static/img/team-02/Shubartsov.jpg';
import Shubartsov_x2 from './static/img/team-02/Shubartsov@2x.jpg';
import Tovmasyan from './static/img/team-02/Tovmasyan.jpg';
import Tovmasyan_x2 from './static/img/team-02/Tovmasyan@2x.jpg';
import Razmyslov from './static/img/team-02/Razmyslov.jpg';
import Razmyslov_x2 from './static/img/team-02/Razmyslov@2x.jpg';
import Ogerelev from './static/img/team-02/Ogerelev.jpg';
import Ogerelev_x2 from './static/img/team-02/Ogerelev@2x.jpg';
import Philippova from './static/img/team-02/Philippova.jpg';
import Philippova_x2 from './static/img/team-02/Philippova@2x.jpg';
import Temrienko from './static/img/team-02/Temrienko.jpg';
import Temrienko_x2 from './static/img/team-02/Temrienko@2x.jpg';
import Maksimov from './static/img/team-02/Maksimov.jpg';
import Maksimov_x2 from './static/img/team-02/Maksimov@2x.jpg';
import Kornilov from './static/img/team-02/Kornilov.jpg';
import Kornilov_x2 from './static/img/team-02/Kornilov@2x.jpg';
import Livanova from './static/img/team-02/Livanova.jpg';
import Livanova_x2 from './static/img/team-02/Livanova@2x.jpg';
import Sussky from './static/img/team-02/Sussky.jpg';
import Sussky_x2 from './static/img/team-02/Sussky@2x.jpg';
import Ofitserova from './static/img/team-02/Ofitserova.jpg';
import Ofitserova_x2 from './static/img/team-02/Ofitserova@2x.jpg';
import Yuryev from './static/img/team-02/Yuryev.jpg';
import Yuryev_x2 from './static/img/team-02/Yuryev@2x.jpg';
import Galochkin from './static/img/team-02/Galochkin.jpg';
import Galochkin_x2 from './static/img/team-02/Galochkin@2x.jpg';
import Kolesnikov from './static/img/team-02/kolesnikov.jpg';
import Kolesnikov_x2 from './static/img/team-02/kolesnikov@2x.jpg';
import Miheev from './static/img/team-02/Miheev.jpg';
import Miheev_x2 from './static/img/team-02/Miheev@2x.jpg';

export default function getProduct(t) {
  return {
    team: [
      {
        name: t('Anton Klevtsov'),
        img: Klevtsov,
        img2: Klevtsov_x2,
        status: t('Product Manager')
      },
      {
        name: t('Pavel Urumyan'),
        img: Urumyan,
        img2: Urumyan_x2,
        status: t('Product Manager')
      },
      {
        name: t('Alexey Nizev'),
        img: Nizev,
        img2: Nizev_x2,
        status: t('Head of Algo Trading')
      },
      {
        name: t('Dmitry Reznikov'),
        img: Reznikov,
        img2: Reznikov_x2,
        status: t('Content Marketing Director')
      },
      {
        name: t('Denis Khvatkov'),
        img: Khvatkov,
        img2: Khvatkov_x2,
        status: t('Product Manager Answrpro')
      },
      {
        name: t('Dmitry Chemankov'),
        img: Chemankov,
        img2: Chemankov_x2,
        status: t('Internal Auditor')
      },
      {
        name: t('Artyom Evseev'),
        img: Evseev,
        img2: Evseev_x2,
        status: t('Risk Manager')
      },
      {
        name: t('Alexandr Zulin'),
        img: Zulin,
        img2: Zulin_x2,
        status: t('Graphic Designer')
      },
      {
        name: t('Alexandr Rusko'),
        img: Rusko,
        img2: Rusko_x2,
        status: t('Community Manager')
      },
      {
        name: t('Andrey Shubartsov'),
        img: Shubartsov,
        img2: Shubartsov_x2,
        status: t('Community Manager')
      },
      {
        name: t('Artur Tovmasyan'),
        img: Tovmasyan,
        img2: Tovmasyan_x2,
        status: t('Community Manager')
      },
      {
        name: t('Ivan Razmyslov'),
        img: Razmyslov,
        img2: Razmyslov_x2,
        status: t('Head of Support')
      },
      {
        name: t('Sergey Ogerelev'),
        img: Ogerelev,
        img2: Ogerelev_x2,
        status: t('Financial Analyst')
      },
      {
        name: t('Alexandra Philippova'),
        img: Philippova,
        img2: Philippova_x2,
        status: t('Community Manager')
      },
      {
        name: t('Valeriya Temrienko'),
        img: Temrienko,
        img2: Temrienko_x2,
        status: t('Illustrator')
      },
      {
        name: t('Leonid Maksimov'),
        img: Maksimov,
        img2: Maksimov_x2,
        status: t('User Acquisition Manager')
      },
      {
        name: t('Kirill Kornilov'),
        img: Kornilov,
        img2: Kornilov_x2,
        status: t('User Acquisition Manager')
      },
      {
        name: t('Svetlana Livanova'),
        img: Livanova,
        img2: Livanova_x2,
        status: t('Head of Operations')
      },
      {
        name: t('Vladislav Suski'),
        img: Sussky,
        img2: Sussky_x2,
        status: t('Localization Manager')
      },
      {
        name: t('Daria Ofitserova'),
        img: Ofitserova,
        img2: Ofitserova_x2,
        status: t('Journalist')
      },
      {
        name: t('Georgy Yuryev'),
        img: Yuryev,
        img2: Yuryev_x2,
        status: t('Financial Analyst')
      },
      {
        name: t('Vyacheslav Galochkin'),
        img: Galochkin,
        img2: Galochkin_x2,
        status: t('Customer Support')
      },
      {
        name: t('Ivan Kolesnikov'),
        img: Kolesnikov,
        img2: Kolesnikov_x2,
        status: t('UI Designer')
      },
      {
        name: t('Sergey Miheev'),
        img: Miheev,
        img2: Miheev_x2,
        status: t('Product Manager')
      }
    ]
  };
}
