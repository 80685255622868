import Davidov from './static/img/team-03/Davidov.jpg';
import Davidov_x2 from './static/img/team-03/Davidov@2x.jpg';
import Litvinov from './static/img/team-03/Litvinov.jpg';
import Litvinov_x2 from './static/img/team-03/Litvinov@2x.jpg';
import Burlaka from './static/img/team-03/Burlaka.jpg';
import Burlaka_x2 from './static/img/team-03/Burlaka@2x.jpg';
import Glushenkov from './static/img/team-03/Glushenkov.jpg';
import Glushenkov_x2 from './static/img/team-03/Glushenkov@2x.jpg';
import Ovchinnikov from './static/img/team-03/Ovchinnikov.jpg';
import Ovchinnikov_x2 from './static/img/team-03/Ovchinnikov@2x.jpg';
import Berdeleva from './static/img/team-03/Berdeleva.jpg';
import Berdeleva_x2 from './static/img/team-03/Berdeleva@2x.jpg';
import Markelov from './static/img/team-03/Markelov.jpg';
import Markelov_x2 from './static/img/team-03/Markelov@2x.jpg';
import Grigoryev from './static/img/team-03/Grigoryev.jpg';
import Grigoryev_x2 from './static/img/team-03/Grigoryev@2x.jpg';
import Ostrovskiy from './static/img/team-03/Ostrovskiy.jpg';
import Ostrovskiy_x2 from './static/img/team-03/Ostrovskiy@2x.jpg';
import Savinov from './static/img/team-03/Savinov.jpg';
import Savinov_x2 from './static/img/team-03/Savinov@2x.jpg';
import Sobol from './static/img/team-03/Sobol.jpg';
import Sobol_x2 from './static/img/team-03/Sobol@2x.jpg';
import Zakirova from './static/img/team-03/Zakirova.jpg';
import Zakirova_x2 from './static/img/team-03/Zakirova@2x.jpg';
import Lennik from './static/img/team-03/Lennik.jpg';
import Lennik_x2 from './static/img/team-03/Lennik@2x.jpg';
import Ilkevich from './static/img/team-03/Ilkevich.jpg';
import Ilkevich_x2 from './static/img/team-03/Ilkevich@2x.jpg';
import Serbis from './static/img/team-03/Serbis.jpg';
import Serbis_x2 from './static/img/team-03/Serbis@2x.jpg';
import Muravyov from './static/img/team-03/Muravyov.jpg';
import Muravyov_x2 from './static/img/team-03/Muravyov@2x.jpg';
import Ivanov from './static/img/team-03/Ivanov.jpg';
import Ivanov_x2 from './static/img/team-03/Ivanov@2x.jpg';
import Krasnukhin from './static/img/team-03/Krasnukhin.jpg';
import Krasnukhin_x2 from './static/img/team-03/Krasnukhin@2x.jpg';
import Korolyov from './static/img/team-03/Korolyov.jpg';
import Korolyov_x2 from './static/img/team-03/Korolyov@2x.jpg';
import Karyakina from './static/img/team-03/Karyakina.jpg';
import Karyakina_x2 from './static/img/team-03/Karyakina@2x.jpg';
import Koshcheeva from './static/img/team-03/Koshcheeva.jpg';
import Koshcheeva_x2 from './static/img/team-03/Koshcheeva@2x.jpg';
import Kobyakova from './static/img/team-03/Kobyakova.jpg';
import Kobyakova_x2 from './static/img/team-03/Kobyakova@2x.jpg';

export default function getDevelopers(t) {
  return {
    team: [
      {
        name: t('Egor Davydov'),
        img: Davidov,
        img2: Davidov_x2,
        status: t('Team Lead UTEX Back-End Team Back-End Developer')
      },
      {
        name: t('Vadim Litvinov'),
        img: Litvinov,
        img2: Litvinov_x2,
        status: t('Team Lead Aurora')
      },
      {
        name: t('Denis Burlaka'),
        img: Burlaka,
        img2: Burlaka_x2,
        status: t('Back-End Developer')
      },
      {
        name: t('Vladimir Glushenkov'),
        img: Glushenkov,
        img2: Glushenkov_x2,
        status: t('Back-End Developer')
      },
      {
        name: t('Vyacheslav Ovchinnikov'),
        img: Ovchinnikov,
        img2: Ovchinnikov_x2,
        status: t('Front-End Developer')
      },
      {
        name: t('Maria Bredeleva'),
        img: Berdeleva,
        img2: Berdeleva_x2,
        status: t('QA Engineer')
      },
      {
        name: t('Anton Markelov'),
        img: Markelov,
        img2: Markelov_x2,
        status: t('Ops Engineer')
      },
      {
        name: t('Alexander Grigoryev'),
        img: Grigoryev,
        img2: Grigoryev_x2,
        status: t('Back-End Developer')
      },
      {
        name: t('Alexander Ostrovskiy'),
        img: Ostrovskiy,
        img2: Ostrovskiy_x2,
        status: t('Back-End Developer')
      },
      {
        name: t('Alexey Savinov'),
        img: Savinov,
        img2: Savinov_x2,
        status: t('Ops Engineer')
      },
      {
        name: t('Daniil Sobol'),
        img: Sobol,
        img2: Sobol_x2,
        status: t('Back-End Developer')
      },
      {
        name: t('Daria Zakirova'),
        img: Zakirova,
        img2: Zakirova_x2,
        status: t('Web Developer')
      },
      {
        name: t('Eugeniy Lennik'),
        img: Lennik,
        img2: Lennik_x2,
        status: t('QA Engineer')
      },
      {
        name: t('Ilya Serbis'),
        img: Serbis,
        img2: Serbis_x2,
        status: t('Back-End Developer')
      },
      {
        name: t('Julia Ilkevich'),
        img: Ilkevich,
        img2: Ilkevich_x2,
        status: t('QA Engineer')
      },
      {
        name: t('Pavel Muravyov'),
        img: Muravyov,
        img2: Muravyov_x2,
        status: t('Team Lead General Services Back-End Developer')
      },
      {
        name: t('Stepan Ivanov'),
        img: Ivanov,
        img2: Ivanov_x2,
        status: t('Front-End Developer')
      },
      {
        name: t('Vadim Krasnukhin'),
        img: Krasnukhin,
        img2: Krasnukhin_x2,
        status: t('Front-End Developer')
      },
      {
        name: t('Ilya Korolev'),
        img: Korolyov,
        img2: Korolyov_x2,
        status: t('QA Engineer')
      },
      {
        name: t('Lena Karyakina'),
        img: Karyakina,
        img2: Karyakina_x2,
        status: t('Web Developer')
      },
      {
        name: t('Marina Koscheeva'),
        img: Koshcheeva,
        img2: Koshcheeva_x2,
        status: t('Back-End Developer')
      },
      {
        name: t('Sasha Kobyakova'),
        img: Kobyakova,
        img2: Kobyakova_x2,
        status: t('Team Coach')
      }
    ]
  };
}
