import styled from 'react-emotion';
import album from './static/img/album.svg';

export const PastEventBlock = styled('div')`
  display: flex;
  flex-direction: column;
  margin-right: 2.5rem;

  @media (max-width: 768px) {
    width: 100%;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const EventPreview = styled('div')`
  position: relative;
  width: 38.3rem;
  height: 21.5rem;
  
  
  @media (max-width: 768px) {
    position: relative;
    display: block;
    padding: 56.37% 0 0 0;
    width: 100%;
    height: auto;
    overflow: hidden;
  }
  
  img {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    transition: transform 0.5s ease;
  
    &:hover,
    &:focus {
      transform: scale(1.05);
      transition: transform 0.5s ease;
    }
    
    @media (max-width: 768px) {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      max-width: 100%;
      max-height: 100%;
      transform: none;
      transition: none;
      
      &:hover,
      &:focus {
        transform: none;
        transition: none;
      }
    }
  }
  
  &::before {
    z-index: 1;
    content: '';
    position: absolute;
    bottom: 1.2rem;
    left: 1.6rem;
    width: 2.5rem;
    height: 2rem;
    background-image: url(${album});
    background-repeat: no-repeat;
`;

export const EventLabel = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.4rem;
  padding-top: 0.8rem;
  width: 38.2rem;

  @media (max-width: 768px) {
    margin-bottom: 2rem;
    padding: 1rem 1.6rem 0;
    width: 100%;
  }
`;

export const EventName = styled('span')`
  margin-bottom: 0.5rem;
  font-weight: 600;
`;

export const EventTime = styled('span')`
  color: rgba(0, 0, 0, 0.3);
`;
