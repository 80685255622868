import styled from 'react-emotion';

export const Links = styled('div')`
  margin-top: auto;
  padding: 0;

  ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    padding: 0 0 0 2.4rem;
  }

  li {
    line-height: 2.2rem;
    list-style: none;
    cursor: pointer;

    &:first-of-type {
      padding-top: 2rem;
    }

    &:last-of-type {
      margin-bottom: 2.4rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    &:hover,
    &:focus {
      color: #001cba;
    }
  }

  a {
    display: inline-block;
    padding-bottom: 1.8rem;
    width: 100%;
    color: inherit;
    text-decoration: none;
  }
`;

export const LangWrapper = styled('div')`
  position: relative;
  padding: 0 0 0 2.4rem;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 1.8rem;
    height: 1.8rem;
    background-color: #ed4040;
    border-radius: 50%;
  }
`;
