import styled from 'react-emotion';

export const PartnersBlock = styled('section')`
  padding: 4.8rem 0 9.3rem;
  background-color: #f5f5f5;

  @media (max-width: 1660px) and (min-width: 1200px) {
    margin: 0 3rem;
  }

  @media (max-width: 768px) {
    margin: 0;
    padding: 2.7rem 1.6rem;
  }

  h2 {
    margin: 0 auto 6rem;
    max-width: 120rem;
    font-family: 'Dewi', Verdana, sans-serif;
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 4rem;

    @media (max-width: 768px) {
      margin: 0 auto 3rem;
      font-size: 2.4rem;
      text-align: center;
    }
  }
`;

export const PartnerImages = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 120rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Image = styled('img')`
  display: inline-block;
  max-width: 23.3rem;

  width: ${props => {
    if (props.width.isHyperledger) {
      return '21.9';
    } else if (props.width.isElem) {
      return '16.8';
    } else if (props.width.isMama) {
      return '14.4';
    } else if (props.width.isRTS) {
      return '11';
    } else if (props.width.isIcorating) {
      return '23.3';
    }
    return '';
  }}rem;
`;
