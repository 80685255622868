import React, { Component } from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { withI18n } from 'react-i18next';
import getTeam from './team';
import getProduct from './product';
import getDevelopers from './developers';
import getNetworking from './networking';

import {
  activeClass,
  TeamBlock,
  Main,
  Header,
  TabNumber,
  TabName,
  TeamWrapper,
  Person,
  FounderPhotoWrapper,
  PhotoWrapper,
  Info,
  Name,
  Status,
  Education
} from './styles';

class Team extends Component {
  render() {
    const { t } = this.props;
    const team = getTeam(t);
    const developers = getDevelopers(t);
    const networking = getNetworking(t);
    const product = getProduct(t);

    return (
      <TeamBlock>
        <Main>
          <Tabs selectedTabClassName={activeClass}>
            <Header>
              <h2>{t('Team')}</h2>
              <TabList>
                <Tab>
                  <TabNumber>4+7</TabNumber>
                  <TabName>
                    <span>{t('Founders and Top Management1')}</span>
                    <span>{t('Founders and Top Management2')}</span>
                  </TabName>
                </Tab>
                <Tab>
                  <TabNumber>24</TabNumber>
                  <TabName>
                    <span>{t('Product Team1')}</span>
                    <span>{t('Product Team2')}</span>
                  </TabName>
                </Tab>
                <Tab>
                  <TabNumber>22</TabNumber>
                  <TabName>
                    <span>{t('Development Team1')}</span>
                    <span>{t('Development Team2')}</span>
                  </TabName>
                </Tab>
                <Tab>
                  <TabNumber>15</TabNumber>
                  <TabName>
                    <span>{t('Networking and Sales1')}</span>
                    <span>{t('Networking and Sales2')}</span>
                  </TabName>
                </Tab>
              </TabList>
            </Header>

            <TabPanel>
              <TeamWrapper founders>
                {team.founders.map(({ name, img, img2, status, edu }, index) => (
                  <Person founder key={index}>
                    <FounderPhotoWrapper>
                      <img src={img} srcSet={`${img2} 2x`} alt={name} />
                    </FounderPhotoWrapper>
                    <Info>
                      <Name founder>{name}</Name>
                      <Status founder>{status}</Status>
                      <Education>{edu}</Education>
                    </Info>
                  </Person>
                ))}
              </TeamWrapper>
              <div>
                <TeamWrapper managers>
                  {team.managers.map(person => this.renderManeger(person))}
                </TeamWrapper>
              </div>
            </TabPanel>
            <TabPanel>
              <div>
                <TeamWrapper>
                  {product.team.slice(0, 7).map(person => this.renderPerson(person))}
                </TeamWrapper>
                <TeamWrapper>
                  {product.team.slice(7, 14).map(person => this.renderPerson(person))}
                </TeamWrapper>
                <TeamWrapper>
                  {product.team.slice(14, 21).map(person => this.renderPerson(person))}
                </TeamWrapper>
                <TeamWrapper last>
                  {product.team.slice(21, 27).map(person => this.renderPerson(person))}
                </TeamWrapper>
              </div>
            </TabPanel>
            <TabPanel>
              <div>
                <TeamWrapper>
                  {developers.team.slice(0, 7).map(person => this.renderPerson(person))}
                </TeamWrapper>
                <TeamWrapper>
                  {developers.team.slice(7, 14).map(person => this.renderPerson(person))}
                </TeamWrapper>
                <TeamWrapper>
                  {developers.team.slice(14, 21).map(person => this.renderPerson(person))}
                </TeamWrapper>
                <TeamWrapper last>
                  {developers.team.slice(21, 26).map(person => this.renderPerson(person))}
                </TeamWrapper>
              </div>
            </TabPanel>
            <TabPanel>
              <div>
                <TeamWrapper>
                  {networking.team.slice(0, 7).map(person => this.renderPerson(person))}
                </TeamWrapper>
                <TeamWrapper>
                  {networking.team.slice(7, 14).map(person => this.renderPerson(person))}
                </TeamWrapper>
                <TeamWrapper last>
                  {networking.team.slice(14, 21).map(person => this.renderPerson(person))}
                </TeamWrapper>
              </div>
            </TabPanel>
          </Tabs>
        </Main>
      </TeamBlock>
    );
  }

  renderManeger({ name, status, img, img2 }) {
    return (
      <Person key={name} manager>
        <PhotoWrapper manager>
          <img src={img} srcSet={`${img2} 2x`} alt={name} />
        </PhotoWrapper>
        <Info>
          <Name>{name}</Name>
          <Status>{status}</Status>
        </Info>
      </Person>
    );
  }

  renderPerson({ name, status, img, img2 }) {
    return (
      <Person key={name}>
        <PhotoWrapper>
          <img src={img} srcSet={`${img2} 2x`} alt={name} />
        </PhotoWrapper>
        <Info>
          <Name>{name}</Name>
          <Status>{status}</Status>
        </Info>
      </Person>
    );
  }
}

export default withI18n()(Team);
