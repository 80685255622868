import React, { Component } from 'react';
import { withI18n } from 'react-i18next';

import forbes from './static/img/forbes.png';
import forbes_2 from './static/img/forbes@2x.png';
import finone from './static/img/finone.png';
import finone_2 from './static/img/finone@2x.png';
import me from './static/img/me.png';
import me_2 from './static/img/me@2x.png';
import rns from './static/img/rns.png';
import rns_2 from './static/img/rns@2x.png';
import finfin from './static/img/finfin.png';
import finfin_2 from './static/img/finfin@2x.png';
import cointele from './static/img/cointele.png';
import cointele_2 from './static/img/cointele@2x.png';
import profit from './static/img/profit.png';
import profit_2 from './static/img/profit@2x.png';
import infoption from './static/img/infoption.png';
import infoption_2 from './static/img/infoption@2x.png';
import atameken from './static/img/atameken.png';
import atameken_2 from './static/img/atameken@2x.png';
import coinspeaker from './static/img/coinspeaker.png';
import coinspeaker_2 from './static/img/coinspeaker@2x.png';
import forklog from './static/img/forklog.png';
import forklog_2 from './static/img/forklog@2x.png';
import voice from './static/img/voice.png';
import voice_2 from './static/img/voice@2x.png';
import rbk from './static/img/rbk.png';
import rbk_2 from './static/img/rbk@2x.png';
import mayak from './static/img/mayak.png';
import mayak_2 from './static/img/mayak@2x.png';
import capital from './static/img/capital.png';
import capital_2 from './static/img/capital@2x.png';
import finam from './static/img/finam.png';
import finam_2 from './static/img/finam@2x.png';
import career from './static/img/career.png';
import career_2 from './static/img/career@2x.png';
import people from './static/img/people.png';
import people_2 from './static/img/people@2x.png';

import { AboutBlock, AboutImages, ImageBlock } from './styles';

class About extends Component {
  render() {
    const { t } = this.props;

    return (
      <AboutBlock>
        <h2>{t('In the Media')}</h2>
        <AboutImages>
          <ImageBlock>
            <img src={forbes} srcSet={`${forbes_2} 2x`} alt="Forbes" />
          </ImageBlock>
          <ImageBlock>
            <img src={finone} srcSet={`${finone_2} 2x`} alt="Fin-one" />
          </ImageBlock>
          <ImageBlock>
            <img src={me} srcSet={`${me_2} 2x`} alt="Me" />
          </ImageBlock>
          <ImageBlock>
            <img src={rns} srcSet={`${rns_2} 2x`} alt="RNS" />
          </ImageBlock>
          <ImageBlock>
            <img src={finfin} srcSet={`${finfin_2} 2x`} alt="Fin-Fin" />
          </ImageBlock>
          <ImageBlock>
            <img src={cointele} srcSet={`${cointele_2} 2x`} alt="Cointele" />
          </ImageBlock>
          <ImageBlock>
            <img src={profit} srcSet={`${profit_2} 2x`} alt="Profit" />
          </ImageBlock>
          <ImageBlock>
            <img src={infoption} srcSet={`${infoption_2} 2x`} alt="Infoption" />
          </ImageBlock>
          <ImageBlock>
            <img src={atameken} srcSet={`${atameken_2} 2x`} alt="Atameken" />
          </ImageBlock>
          <ImageBlock>
            <img src={coinspeaker} srcSet={`${coinspeaker_2} 2x`} alt="Coinspeaker" />
          </ImageBlock>
          <ImageBlock>
            <img src={forklog} srcSet={`${forklog_2} 2x`} alt="fFrklog" />
          </ImageBlock>
          <ImageBlock>
            <img src={voice} srcSet={`${voice_2} 2x`} alt="Voice" />
          </ImageBlock>
          <ImageBlock>
            <img src={rbk} srcSet={`${rbk_2} 2x`} alt="RBK" />
          </ImageBlock>
          <ImageBlock>
            <img src={mayak} srcSet={`${mayak_2} 2x`} alt="Mayak" />
          </ImageBlock>
          <ImageBlock>
            <img src={capital} srcSet={`${capital_2} 2x`} alt="Capital" />
          </ImageBlock>
          <ImageBlock>
            <img src={finam} srcSet={`${finam_2} 2x`} alt="Finam" />
          </ImageBlock>
          <ImageBlock>
            <img src={career} srcSet={`${career_2} 2x`} alt="Career" />
          </ImageBlock>
          <ImageBlock>
            <img src={people} srcSet={`${people_2} 2x`} alt="People" />
          </ImageBlock>
        </AboutImages>
      </AboutBlock>
    );
  }
}

export default withI18n()(About);
