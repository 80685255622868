import styled from 'react-emotion';

export const ProductBlock = styled('section')`
  display: flex;
  justify-content: space-between;
  padding-top: 13.5rem;

  @media (max-width: 1660px) and (min-width: 1200px) {
    margin: 0 3rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0;
    padding-top: 0;
  }
`;

export const Back = styled('div')`
  padding: 4.3rem 0 5.3rem;
  width: 79.5rem;
  background-color: #f5f5f5;
  margin-right: ${props => (props.first ? '3rem' : '0')};

  @media (max-width: 1200px) {
    margin-right: ${props => (props.first ? '2rem' : '0')};
  }

  @media (max-width: 768px) {
    margin: 0 0 1rem;
    padding: 2.8rem 1.6rem;
    width: 100%;
  }
`;

export const ProductWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin: 0 auto 1.6rem;
    font-family: 'Dewi', Verdana, sans-serif;
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 4rem;
    text-align: center;

    @media (max-width: 768px) {
      margin: 0 auto 6px;
      text-align: center;
    }

    @media (max-width: 435px) {
      font-size: 3rem;
      line-height: 3.6rem;
    }

    @media (max-width: 360px) {
      font-size: 2.6rem;
      line-height: 3rem;
    }
  }

  p {
    margin: 0 0 3px;
    max-width: 47.4rem;
    min-height: 6rem;
    text-align: center;

    @media (max-width: 768px) {
      margin: 0 0 1rem;
      min-height: 0;
    }
  }

  a {
    display: inline-block;
    margin-bottom: 4.6rem;
    padding: 1rem 2.5rem 1.1rem;
    color: #ffffff;
    text-decoration: none;
    background-color: #2f4efe;
    border-radius: 100px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);

    &:hover,
    &:focus {
      background-color: #001cba;
    }

    @media (max-width: 768px) {
      margin-bottom: ${props => (props.utex ? '2.3rem' : '0')};
    }
  }

  div {
    width: 100%;
    max-width: 74.3rem;
  }

  img {
    display: block;
    margin: 0 auto;
    padding: 0 1rem;
    width: 100%;

    @media (max-width: 768px) {
      padding: 0;
    }
  }
`;
