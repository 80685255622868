import React, { Component } from 'react';
import { withI18n } from 'react-i18next';

import utex from './static/img/hero-utex.png';
import utex_2 from './static/img/hero-utex@2x.png';
import investment from './static/img/investment.png';
import investment_2 from './static/img/investment@2x.png';
import investment_en from './static/img/investment_en.png';
import investment_en_2 from './static/img/investment_en@2x.png';

import { ProductBlock, ProductWrapper, Back } from './styles';

class Product extends Component {
  render() {
    const { t, lng } = this.props;

    return (
      <ProductBlock>
        <Back first>
          <ProductWrapper utex>
            <h2>{t('UTEX Crypto Exchange')}</h2>
            <p>
              {t(
                'A reliable cryptocurrency exchange for beginners and professional crypto traders'
              )}
            </p>
            <a href="https://utex.io/">{t('Go to Exchange')}</a>
            <div>
              <img src={utex} srcSet={`${utex_2} 2x`} alt={t('UTEX Crypto Exchange')} />
            </div>
          </ProductWrapper>
        </Back>
        <Back>
          <ProductWrapper>
            <h2>{t('Investing with UT')}</h2>
            <p>{t('A showcase of investment ideas for beginners and professional investors')}</p>
            <a href="https://unitedtraders.com/investments">{t('Give it a try')}</a>
            <div>
              {lng === 'ru' && (
                <img src={investment} srcSet={`${investment_2} 2x`} alt={t('Investing with UT')} />
              )}
              {lng === 'en' && (
                <img
                  src={investment_en}
                  srcSet={`${investment_en_2} 2x`}
                  alt={t('Investing with UT')}
                />
              )}
            </div>
          </ProductWrapper>
        </Back>
      </ProductBlock>
    );
  }
}

export default withI18n()(Product);
