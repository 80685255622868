import styled from 'react-emotion';

export const FooterWrapper = styled('footer')`
  padding: 11.8rem 0 14.7rem;

  @media (max-width: 768px) {
    padding: 5.1rem 0 5.4rem;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 4.2rem;
    font-family: 'Dewi', Verdana, sans-serif;
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 4rem;
    text-align: center;

    @media (max-width: 768px) {
      margin: 0 1.6rem 2rem;
      font-size: 2.4rem;
      line-height: 3rem;
    }
  }
`;

export const InfoWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin: 0 auto;
  max-width: 65rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Contacts = styled('div')`
  display: flex;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.8rem;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.2rem;
  }

  div {
    display: flex;

    @media (max-width: 768px) {
      margin-bottom: 1.1rem;
    }
  }

  a {
    margin: 0 2.5rem 0 0;
    color: rgba(0, 0, 0, 0.6);
    text-decoration: none;

    &:hover,
    &:focus {
      color: #001cba;
      cursor: pointer;
    }

    @media (max-width: 768px) {
      &:first-child {
        margin-right: 3rem;
      }
      margin: 0;
    }
  }
`;

export const LicenseInfo = styled('div')`
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: rgba(0, 0, 0, 0.6);

  a {
    color: rgba(0, 0, 0, 0.6);
    text-decoration: none;

    &:first-child {
      margin-right: 0.4rem;

      &:hover,
      &:focus {
        color: #001cba;
        cursor: pointer;
      }
    }
  }
`;
