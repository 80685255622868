import styled, { css } from 'react-emotion';

export const activeClass = css`
  color: #000 !important;
  transition: all 0.5s ease;

  &::after {
    content: '';
    position: absolute;
    bottom: -3.4rem;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: #000;
    transition: all 0.5s ease;
  }
`;

export const TeamBlock = styled('section')`
  position: relative;
  margin-bottom: 3rem;
  padding: 4.8rem 0 9.3rem;
  background-color: #f5f5f5;

  @media (max-width: 1660px) and (min-width: 1200px) {
    margin: 0 3rem 3rem;
  }

  @media (max-width: 1200px) {
    margin-bottom: 2rem;
  }

  @media (max-width: 768px) {
    margin: 0 0 1rem;
    padding: 9.5rem 0 2.7rem;
  }
`;

export const Main = styled('div')`
  margin: 0 auto;
  max-width: 120rem;
`;

export const Header = styled('div')`
  display: flex;
  align-items: center;
  margin: 0 0 5rem;
  padding-bottom: 3.2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  @media (max-width: 905px) {
    padding: 0 1.6rem 3.2rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0 0 2rem;
    padding: 0 0 3.2rem;
    width: 100%;
    overflow: auto;
  }

  h2 {
    margin: 0 17.5rem 0 0;
    font-family: 'Dewi', Verdana, sans-serif;
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 4rem;

    @media (max-width: 905px) {
      margin: 0 5.5rem 0 0;
    }

    @media (max-width: 768px) {
      position: absolute;
      top: 3.6rem;
      left: 50%;
      margin: 0 0 2.7rem 0;
      font-size: 2.4rem;
      text-align: center;
      transform: translate(-50%, 0);
    }
  }

  ul {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;

    @media (max-width: 905px) {
      align-self: center;
      padding: 0 1.6rem;
    }

    @media (max-width: 768px) {
      align-self: flex-start;
      overflow: hidden;
    }
  }

  li {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-right: 5rem;
    list-style: none;
    color: #2f4efe;
    cursor: pointer;
    transition: all 0.5s ease;

    &::after {
      transition: all 0.5s ease;
    }

    &:hover,
    &:focus {
      color: #001cba;
    }

    @media (max-width: 768px) {
      margin-right: 3rem;
      text-align: center;
    }

    @media (max-width: 400px) {
      display: flex;
      justify-content: center;
      margin-right: 0;
      width: 35vw;
      min-width: 0;
    }

    @media (max-width: 330px) {
      width: 40vw;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const TabNumber = styled('span')`
  margin-right: 1rem;
  font-family: 'Dewi', Verdana, sans-serif;
  font-size: 3.6rem;
  line-height: 3.6rem;
  font-weight: 700;
  text-align: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const TeamWrapper = styled('div')`
  display: flex;
  justify-content: ${props => (props.last ? 'center' : 'space-between')};
  margin-bottom ${props => (props.founders ? '6.6rem' : '1.9rem')};
  
  
  @media (max-width: 905px) {
    padding: 0 1.6rem;
  }
  
    & > div {
      &:not(:last-of-type) {
        margin-right: ${props => (props.last ? '2.6rem' : '0')};
        
        @media (max-width: 768px) {
          margin-right: ${props => (props.last ? '0' : '0')};
        }
      }
    }
    
  @media (max-width: 768px) {
    justify-content: ${props => (props.last ? 'space-between' : 'space-between')};
    flex-wrap: wrap;
    margin-bottom ${props => (props.founders ? '2rem' : '0')};
    padding: 0 1.6rem;
    border-bottom: ${props => (props.founders ? '1px solid rgba(0, 0, 0, 0.1)' : 'none')};
`;

export const TabName = styled('div')`
  display: flex;
  flex-direction: column;
  line-height: 1.6rem;
`;

export const Person = styled('div')`
  width: ${props => (props.founder ? '25rem' : '15rem')};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 2rem;
    width: ${props => (props.founder ? '32rem' : '32rem')};
  }
`;

export const PhotoWrapper = styled('div')`
  margin-bottom: 1rem;

  width: 13rem;
  height: 15rem;

  @media (max-width: 768px) {
    margin: 0 2rem 0 0;
    width: 8rem;
    height: 8rem;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 50%;
    border: none;

    @media (max-width: 768px) {
      width: 8rem;
      height: 8rem;
    }
  }
`;

export const FounderPhotoWrapper = styled('div')`
  margin-bottom: 1rem;

  width: 25rem;
  height: 25rem;

  @media (max-width: 768px) {
    margin: 0 2rem 0 0;
    width: 8rem;
    height: 8rem;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 50%;
    border: none;

    @media (max-width: 768px) {
      width: 8rem;
      height: 8rem;
    }
  }
`;

export const Info = styled('div')`
  display: flex;
  flex-direction: column;
  text-align: center;

  @media (max-width: 768px) {
    text-align: left;
  }
`;

export const Name = styled('span')`
  margin-bottom: ${props => (props.founder ? '5px' : '0')};
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 1.9rem;
  letter-spacing: 1.3px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    margin: 0;
    line-height: 3rem;
  }
`;

export const Status = styled('span')`
  font-size: ${props => (props.founder ? '1.5rem' : '1.3rem')};
  line-height: ${props => (props.founder ? '2rem' : '1.8rem')};
  opacity: ${props => (props.founder ? '1' : '0.5')};

  @media (max-width: 768px) {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
`;

export const Education = styled('span')`
  line-height: 1.8rem;
  opacity: 0.5;

  @media (max-width: 768px) {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
`;
