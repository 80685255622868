import styled from 'react-emotion';
import rusFlag from './static/img/ru.png';
import engFlag from './static/img/en.png';

export const LangListOverlay = styled('div')`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 24.2rem;
  height: 100%;
  bottom: 0;

  @media (max-width: 768px) {
    width: 26rem;
  }
`;

export const LangWrapper = styled('div')`
  padding: 0 0 0 2.4rem;
  position: relative;
`;

export const LangTarget = styled('div')`
  position: relative;
  width: 100%;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 1.8rem;
    height: 1.8rem;
    background-image: url(${props => (props.rus ? rusFlag : engFlag)});
    background-size: cover;
    border-radius: 50%;
  }
`;

export const LangList = styled('ul')`
  z-index: 2;
  position: absolute;
  bottom: -2.4rem;
  left: 0;
  right: 0;
  margin: 0;
  padding: 2rem 2rem 2rem;
  width: 100%;
  list-style: none;
  background-color: #fff;
  cursor: pointer;
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.1), 0 0 10px 0 rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    bottom: -2.4rem;
  }
`;

export const LangOption = styled('li')`
  position: relative;
  padding: 0.3rem 0.3rem 0.4rem;
  cursor: pointer;

  &:first-of-type {
    margin-bottom: 1rem;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    width: 1.8rem;
    height: 1.8rem;
    background-image: url(${props => (props.rus ? rusFlag : engFlag)});
    background-size: cover;
    border-radius: 50%;
  }

  &:hover,
  &:focus {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;
