import { css } from 'emotion';

export const headerClass = css`
  position: relative;

  &::before {
    position: absolute;
    content: '';
    bottom: -0.5rem;
    width: 102%;
    height: 2px;
    background-color: #ed4040;
  }
`;

export const sideClass = css`
  position: relative;

  &::before {
    position: absolute;
    content: '';
    left: -2.5rem;
    top: -0.7rem;
    width: 3px;
    height: 4rem;
    background-color: #ed4040;
  }
`;
