import React, { Component } from 'react';
import MenuLink from './components/MenuLink/index';
import { withI18n } from 'react-i18next';

import { MainMenuBlock } from './styles';

class MainMenu extends Component {
  render() {
    const { header, t } = this.props;

    return (
      <MainMenuBlock header={header}>
        <ul>
          <li>
            <MenuLink to="product" header={header}>
              {t('Product')}
            </MenuLink>
          </li>
          <li>
            <MenuLink to="roadmap" header={header} offset={-90}>
              {t('Roadmap')}
            </MenuLink>
          </li>
          <li>
            <MenuLink to="events" header={header}>
              {t('Events')}
            </MenuLink>
          </li>
          <li>
            <MenuLink to="about" header={header}>
              {t('About us')}
            </MenuLink>
          </li>
          <li>
            <MenuLink to="partners" header={header} offset={-65}>
              {t('Partners')}
            </MenuLink>
          </li>
        </ul>
      </MainMenuBlock>
    );
  }
}

export default withI18n()(MainMenu);
