import React, { Component } from 'react';
import { withI18n } from 'react-i18next';
import { Links } from './styles';

class SideMenuLinks extends Component {
  render() {
    const { t } = this.props;

    return (
      <Links>
        <ul>
          <li>
            <a
              href={'https://unitedtraders.com/personal/invoices'}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('My Accounts')}
            </a>
          </li>
          <li>
            <a
              href={'https://unitedtraders.com/education'}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Education')}
            </a>
          </li>
          <li>
            <a href={'https://unitedtraders.com/trading'} target="_blank" rel="noopener noreferrer">
              {t('Trading')}
            </a>
          </li>
          <li>
            <a href={'https://utex.io/'} target="_blank" rel="noopener noreferrer">
              {t('UTEX')}
            </a>
          </li>
          <li>
            <a
              href={'https://unitedtraders.com/partnership'}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('AffiliatesMenu')}
            </a>
          </li>
          <li>
            <a
              href={'https://unitedtraders.com/contacts'}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Contacts')}
            </a>
          </li>
        </ul>
      </Links>
    );
  }
}

export default withI18n()(SideMenuLinks);
