import styled from 'react-emotion';
import twitter from './static/img/twitter.svg';
import twitterHover from './static/img/twitter-hover.svg';
import menu from './static/img/menu.svg';
import menuHover from './static/img/menu-hover.svg';

export const HeaderBlock = styled('header')`
  position: fixed;
  z-index: 2;
  margin: 0 auto;
  padding: ${props => (props.scrolled ? '2rem 3rem 2.6rem' : '2.2rem 3rem 3.6rem')};
  width: 100%;
  height: ${props => (props.scrolled ? '8rem' : 'auto')};
  max-width: 162rem;
  min-height: ${props => (props.scrolled ? '8rem' : '9rem')};
  background-color: #fff;
  box-shadow: ${props => (props.scrolled ? '0 1px 0 0 rgba(0,0,0,0.1)' : 'none')};
  transition: ${props => (props.scrolled ? 'all 0.5s ease' : 'all 0.5s ease')};

  @media (min-width: 1661px) {
    padding-left: 0;
    padding-right: 0;
  }

  @media (max-width: 768px) {
    position: relative;
    min-height: 8.4rem;
    align-items: flex-start;
    padding: 1.8rem 1.6rem 2.86rem;
  }
`;

export const HeaderWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;

  @media (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Logo = styled('div')`
  width: 18.4rem;

  img {
    display: block;
    width: 100%;
  }

  @media (max-width: 768px) {
    max-width: 13.6rem;
  }
`;

export const Interactive = styled('div')`
  position: relative;
  display: flex;
  align-items: baseline;
`;

export const TwitterButton = styled('div')`
  position: relative;
  margin-right: 4rem;
  color: #2f4efe;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-right: 2.4rem;
  }

  &::before {
    content: '';
    display: inline-block;
    margin-right: 0.3rem;
    width: 2rem;
    height: 2rem;
    background-image: url(${twitter});
    background-repeat: no-repeat;
    background-size: contain;
    vertical-align: middle;
  }

  &:hover,
  &:focus {
    color: #001cba;

    &::before {
      background-image: url(${twitterHover});
    }
  }
`;

export const PopupHeader = styled('div')`
  display: none;

  @media (max-width: 768px) {
    position: absolute;
    display: flex;
    top: 1rem;
    right: 0;
    width: 4rem;
    min-height: 3rem;
    background-color: #fff;
  }
`;

export const PopupClose = styled('div')`
  position: absolute;
  right: 1.5rem;
  top: 0.5rem;
  width: 1.2rem;
  height: 1.2rem;

  img {
    display: block;
    width: 100%;
  }
`;

export const News = styled('span')`
  margin-right: 0.3rem;

  @media (max-width: 768px) {
    margin-right: 1rem;
  }
`;

export const Token = styled('span')`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const MenuButton = styled('button')`
  position: relative;
  padding: 0;
  width: 1.8rem;
  height: 2rem;
  font-size: 0;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  background-image: url(${menu});
  background-size: contain;
  background-repeat: no-repeat;

  &:hover {
    background-image: url(${menuHover});
  }

  @media (max-width: 768px) {
    position: fixed;
    top: 1.9rem;
    right: 0.9rem;
    width: 3.4rem;
    height: 3.4rem;
    background-color: #fff;
    background-size: 1.8rem 1.2rem;
    background-position: center;
    border-radius: 100%;
  }

  @media (max-width: 320px) {
    right: 1.9rem;
  }
`;
