const albums = {
  utBlockchainLife2: [
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/11_01.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/11_02.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/11_03.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/11_04.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/11_05.jpg'
    }
  ],
  utBlockchainLife: [
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/10_1.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/10_6.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/10_5.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/10_4.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/10_3.jpg'
    }
  ],
  utFuturama: [
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-01.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-02.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-03.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-04.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-05.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-06.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-07.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-08.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-09.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-10.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-11.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-12.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-13.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-14.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-15.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-16.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-17.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-18.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-19.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-20.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-21.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-22.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-23.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-24.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-25.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-26.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-27.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-28.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-29.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-30.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-31.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-32.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-33.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-34.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-35.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-36.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-37.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-38.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-39.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-40.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-41.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-42.jpg'
    },
    {
      src: 'https://investment-prod.s3.amazonaws.com/utex/events/9-43.jpg'
    }
  ],
  utBCCruise: [
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-1.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-2.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-3.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-4.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-5.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-6.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-7.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-8.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-9.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-10.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-11.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-12.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-13.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-14.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-15.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-16.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-17.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-18.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-19.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-20.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-21.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-22.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-23.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-24.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-25.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-26.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-27.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-28.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-29.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-30.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-31.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-32.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-33.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-34.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-35.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-36.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-37.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-38.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-39.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-40.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-41.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-42.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-43.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-44.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-45.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-46.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/8-47.jpg' }
  ],
  utVegas: [
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/7-1.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/7-2.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/7-3.jpg' }
  ],
  utHongKong2: [
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/6-1.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/6-2.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/6-3.jpg' }
  ],
  utRBW: [
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/5-1.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/5-2.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/5-3.jpg' }
  ],
  utHongKong: [
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/4-1.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/4-2.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/4-3.jpg' }
  ],
  utFintech: [
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/3-1.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/3-2.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/3-3.jpg' }
  ],
  utBlackshow: [
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/2-1.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/2-2.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/2-3.jpg' }
  ],
  utConference: [
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-1.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-2.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-3.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-4.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-5.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-6.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-7.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-8.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-9.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-10.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-11.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-12.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-13.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-14.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-15.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-16.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-17.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-18.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-19.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-20.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-21.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-22.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-23.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-24.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-25.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-26.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-27.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-28.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-29.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-30.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-31.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-32.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-33.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-34.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-35.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-36.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-37.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-38.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-39.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-40.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-41.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-42.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-43.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-44.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-45.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-46.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-47.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-48.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-49.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-50.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-51.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-52.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-53.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-54.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-55.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-56.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-57.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-58.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-59.jpg' },
    { src: 'https://investment-prod.s3.amazonaws.com/utex/events/1-60.jpg' }
  ]
};

export default albums;
