import React, { Component } from 'react';
import { Link } from 'react-scroll';

import { headerClass, sideClass } from './styles';

class MenuLink extends Component {
  render() {
    const { header, children, ...other } = this.props;

    return (
      <Link
        activeClass={header ? headerClass : sideClass}
        smooth={true}
        spy={true}
        duration={1500}
        offset={-60}
        {...other}
      >
        {children}
      </Link>
    );
  }
}

export default MenuLink;
