import React, { Component } from 'react';
import { LangWrapper, LangList, LangOption, LangTarget, LangListOverlay } from './styles';
import { Language } from '@wapps/gatsby-plugin-i18next';

class SideSwitcher extends Component {
  state = {
    isLangMenuVisible: false
  };

  changeLng = e => {
    this.props.changeLng(e.target.dataset.lng);
    this.setState({ isLangMenuVisible: false });
    e.stopPropagation();
  };

  showLangMenu = () => {
    this.setState({ isLangMenuVisible: true });
  };

  hideLangMenu = () => {
    this.setState({ isLangMenuVisible: false });
  };

  render() {
    return (
      <LangWrapper>
        {this.state.isLangMenuVisible && (
          <LangListOverlay onClick={this.hideLangMenu}>
            <LangList>
              <LangOption rus data-lng="ru" onClick={this.changeLng}>
                Русский
              </LangOption>
              <LangOption data-lng="en" onClick={this.changeLng}>
                English
              </LangOption>
            </LangList>
          </LangListOverlay>
        )}

        {this.props.lng === 'ru' && (
          <LangTarget rus onClick={this.showLangMenu}>
            Русский
          </LangTarget>
        )}
        {this.props.lng === 'en' && <LangTarget onClick={this.showLangMenu}>English</LangTarget>}
      </LangWrapper>
    );
  }
}

export default props => (
  <Language>{lngProps => <SideSwitcher {...props} {...lngProps} />}</Language>
);
