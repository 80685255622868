import React from 'react';
import { Language } from '@wapps/gatsby-plugin-i18next';

import { LangSwitcherBlock, LangList, LangOption } from './styles';

class Switcher extends React.Component {
  state = {
    isLangListVisible: false
  };

  changeLng = e => {
    this.props.changeLng(e.target.dataset.lng);
    this.setState({ isLangListVisible: false });
    e.stopPropagation();
  };

  showLangList = () => {
    this.setState({ isLangListVisible: true });
  };

  render() {
    return (
      <LangSwitcherBlock onClick={this.showLangList}>
        {this.state.isLangListVisible && (
          <LangList>
            <LangOption data-lng="en" onClick={this.changeLng}>
              ENG
            </LangOption>
            <LangOption data-lng="ru" onClick={this.changeLng}>
              RUS
            </LangOption>
          </LangList>
        )}
        {this.props.lng === 'ru' && 'RUS'}
        {this.props.lng === 'en' && 'ENG'}
      </LangSwitcherBlock>
    );
  }
}

export default props => <Language>{lngProps => <Switcher {...props} {...lngProps} />}</Language>;
