import styled from 'react-emotion';

import drop from './static/img/drop.svg';
import dropHover from './static/img/drop-hover.svg';

export const LangSwitcherBlock = styled('div')`
  position: relative;
  margin-right: 2rem;
  padding-right: 1rem;
  text-align: right;
  border-color: transparent;
  background-color: transparent;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: 0.8rem;
    right: 0;
    width: 0.7rem;
    height: 0.5rem;
    background-image: url(${drop});
    background-size: contain;
    background-repeat: no-repeat;
  }

  &:hover,
  &:focus {
    &::after {
      background-image: url(${dropHover});
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const LangList = styled('ul')`
  position: absolute;
  top: 2rem;
  left: 0;
  margin: 0;
  padding: 0;
  padding-right: 1rem;
  width: auto;
  list-style: none;
  background-color: #fff;
  cursor: pointer;
`;

export const LangOption = styled('li')`
  padding-top: 2px;
  text-align: left;
  position: relative;
  cursor: pointer;

  &:hover,
  &:focus {
    color: #2f4efe;
  }
`;
