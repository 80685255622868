import styled from 'react-emotion';

export const OverlaySideMenu = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    background-color: rgba(0, 0, 0, 0.4);
    animation: ${props => (props.isShow ? 'fade-in 0.5s ease both' : 'fade-out 0.5s ease both')};
  }
`;

export const SideMenuBlock = styled('section')`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 2rem 2.4rem 0;
  width: 26rem;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 2rem rgba(0, 41, 91, 0.2);
  overflow-y: scroll;
  animation: ${props =>
    props.isShow ? 'slide-left 0.5s ease both' : 'slide-right 0.5s ease both'};
`;

export const CloseButton = styled('button')`
  z-index: 2;
  position: absolute;
  top: 2.3rem;
  right: 2.2rem;
  padding: 0.5rem;
  width: 2.2rem;
  height: 2.2rem;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  img {
    display: block;
    width: 100%;
    opacity: 0.4;
  }
`;
