import moment from 'moment';
import cards from './static/icons/cards.svg';
import utt from './static/icons/utt.svg';
import aurora from './static/icons/aurora.svg';
import apple from './static/icons/apple.svg';
import release from './static/icons/release.svg';
import pro from './static/icons/pro.svg';
import android from './static/icons/android.svg';
import mobile from './static/icons/mobile.svg';

export default function getEvents(t) {
  return {
    utex: [
      {
        date: moment('29-Mar-2018').format('DD MMM YYYY'),
        desc: t('RoadMapUtexEvent1'),
        passed: true,
        successful: true
      },
      {
        date: moment('15-Jun-2018').format('DD MMM YYYY'),
        desc: t('RoadMapUtexEvent2'),
        passed: true,
        successful: true,
        img: cards
      },
      {
        date: moment('6-Sep-2018').format('D MMM YYYY'),
        desc: t('RoadMapUtexEvent3'),
        passed: true,
        successful: true
      },
      {
        date: moment('25-Apr-2019').format('D MMM YYYY'),
        desc: t('RoadMapUtexEvent4'),
        passed: true,
        successful: false,
        link: t('RoadMapUtexEvent4Link'),
        linkAddress: t('RoadMapUtexEvent4LinkAddress')
      },
      {
        date: t('Q1 2020'),
        desc: t('RoadMapUtexEvent5'),
        passed: true,
        successful: true,
        img: release,
        smаllImg: true
      },
      {
        date: t('Q1 2020'),
        desc: t('RoadMapUtexEvent11'),
        passed: true,
        successful: true,
        img: pro,
        smаllImg: true
      },
      {
        date: t('1 June 2020'),
        desc: t('RoadMapUtexEvent8'),
        passed: true,
        successful: true,
        img: apple,
        smаllImg: true
      },
      {
        date: t('2 July 2020'),
        desc: t('RoadMapUtexEvent13'),
        passed: true,
        successful: true,
        img: aurora,
        link: t('RoadMapUtexEvent13Link'),
        linkAddress: t('RoadMapUtexEvent13LinkAddress')
      },
      {
        date: t('9 July 2020'),
        desc: t('RoadMapUtexEvent7'),
        passed: true,
        successful: true,
        img: utt
      },
      {
        date: t('Q1 2020'),
        desc: t('RoadMapUtexEvent6'),
        passed: false,
        successful: true
      },
      {
        date: t('Q1 2020'),
        desc: t('RoadMapUtexEvent9'),
        passed: false,
        successful: true,
        img: android
      },
      {
        date: t('Q1 2020'),
        desc: t('RoadMapUtexEvent10'),
        passed: false,
        successful: true
      },
      {
        date: t('Q1 2020'),
        desc: t('RoadMapUtexEvent12'),
        passed: false,
        successful: true
      },
      {
        date: t('Q2 2020'),
        desc: t('RoadMapUtexEvent14'),
        passed: false,
        successful: true
      },
      {
        date: t('Q2 2020'),
        desc: t('RoadMapUtexEvent15'),
        passed: false,
        successful: true
      },
      {
        date: t('Q2 2020'),
        desc: t('RoadMapUtexEvent16'),
        passed: false,
        successful: true
      },
      {
        date: t('Subject to favorable environment'),
        desc: t('RoadMapUtexEventLast'),
        passed: false,
        successful: true
      }
    ],
    investment: [
      {
        date: moment('2012').format('YYYY'),
        desc: t('RoadMapInvestmentEvent1'),
        passed: true,
        successful: true
      },
      {
        date: moment('2015').format('YYYY'),
        desc: t('RoadMapInvestmentEvent2'),
        passed: true,
        successful: true
      },
      {
        date: t('Jan 2017'),
        desc: t('RoadMapInvestmentEvent3'),
        passed: true,
        successful: true
      },
      {
        date: t('Jun 2017'),
        desc: t('RoadMapInvestmentEvent4'),
        passed: true,
        successful: true
      },
      {
        date: t('Sep 2017'),
        desc: t('RoadMapInvestmentEvent5'),
        passed: true,
        successful: true
      },
      {
        date: t('Q2 2018'),
        desc: t('RoadMapInvestmentEvent6'),
        passed: true,
        successful: true
      },
      {
        date: t('Q3 2018'),
        desc: t('RoadMapInvestmentEvent7'),
        passed: true,
        successful: true,
        img: mobile
      },
      {
        date: t('Q1 2019'),
        desc: t('RoadMapInvestmentEvent8'),
        passed: true,
        successful: true
      },
      {
        date: moment('2020').format('YYYY'),
        desc: t('RoadMapInvestmentEvent9'),
        passed: false,
        successful: true,
        alignStart: true
      },
      {
        date: t('Subject to favorable environment'),
        desc: t('RoadMapInvestmentEventLast'),
        passed: false,
        successful: true
      }
    ]
  };
}
