import Kholodov from './static/img/team-04/Kholodov.jpg';
import Kholodov_x2 from './static/img/team-04/Kholodov@2x.jpg';
import Gontov from './static/img/team-04/Gontov.jpg';
import Gontov_x2 from './static/img/team-04/Gontov@2x.jpg';
import Nikitin from './static/img/team-04/Nikitin.jpg';
import Nikitin_x2 from './static/img/team-04/Nikitin@2x.jpg';
import Mohov from './static/img/team-04/Mohov.jpg';
import Mohov_x2 from './static/img/team-04/Mohov@2x.jpg';
import Egorova from './static/img/team-04/Egorova.jpg';
import Egorova_x2 from './static/img/team-04/Egorova@2x.jpg';
import Belov from './static/img/team-04/Belov.jpg';
import Belov_x2 from './static/img/team-04/Belov@2x.jpg';
import Negovora from './static/img/team-04/Negovora.jpg';
import Negovora_x2 from './static/img/team-04/Negovora@2x.jpg';
import Nazaretyan from './static/img/team-04/Nazaretyan.jpg';
import Nazaretyan_x2 from './static/img/team-04/Nazaretyan@2x.jpg';
import Kochetov from './static/img/team-04/Kochetov.jpg';
import Kochetov_x2 from './static/img/team-04/Kochetov@2x.jpg';
import Kuznetsov from './static/img/team-04/Kuznetsov.jpg';
import Kuznetsov_x2 from './static/img/team-04/Kuznetsov@2x.jpg';
import Polezhaev from './static/img/team-04/Polezhaev.jpg';
import Polezhaev_x2 from './static/img/team-04/Polezhaev@2x.jpg';
import Shevchenko from './static/img/team-04/Shevchenko.jpg';
import Shevchenko_x2 from './static/img/team-04/Shevchenko@2x.jpg';
import Alexeev from './static/img/team-04/Alexeev.jpg';
import Alexeev_x2 from './static/img/team-04/Alexeev@2x.jpg';
import Rakauskene from './static/img/team-04/Rakauskene.jpg';
import Rakauskene_x2 from './static/img/team-04/Rakauskene@2x.jpg';
import Martynov from './static/img/team-04/Martynov.jpg';
import Martynov_x2 from './static/img/team-04/Martynov@2x.jpg';

export default function getNetworking(t) {
  return {
    team: [
      {
        name: t('Alexandr Kholodov'),
        img: Kholodov,
        img2: Kholodov_x2,
        status: t('Investor Relations')
      },
      {
        name: t('Ilya Gontov'),
        img: Gontov,
        img2: Gontov_x2,
        status: t('Affiliates')
      },
      {
        name: t('Sergey Nikitin'),
        img: Nikitin,
        img2: Nikitin_x2,
        status: t('Affiliates')
      },
      {
        name: t('Alexandr Mohov'),
        img: Mohov,
        img2: Mohov_x2,
        status: t('Sales')
      },
      {
        name: t('Darya Egorova'),
        img: Egorova,
        img2: Egorova_x2,
        status: t('Sales')
      },
      {
        name: t('Konstantin Belov'),
        img: Belov,
        img2: Belov_x2,
        status: t('Head of Sales')
      },
      {
        name: t('Darya Negovora'),
        img: Negovora,
        img2: Negovora_x2,
        status: t('Sales')
      },
      {
        name: t('Alexandr Nazaretyan'),
        img: Nazaretyan,
        img2: Nazaretyan_x2,
        status: t('Sales')
      },
      {
        name: t('Artyom Kochetov'),
        img: Kochetov,
        img2: Kochetov_x2,
        status: t('Sales')
      },
      {
        name: t('Vladislav Kuznetsov'),
        img: Kuznetsov,
        img2: Kuznetsov_x2,
        status: t('Education Products Sales')
      },
      {
        name: t('Grigory Polezhaev'),
        img: Polezhaev,
        img2: Polezhaev_x2,
        status: t('Education Products Sales')
      },
      {
        name: t('Maxim Shevchenko'),
        img: Shevchenko,
        img2: Shevchenko_x2,
        status: t('Sales')
      },
      {
        name: t('Maxim Alexeev'),
        img: Alexeev,
        img2: Alexeev_x2,
        status: t('Sales')
      },
      {
        name: t('Julia Rakauskene'),
        img: Rakauskene,
        img2: Rakauskene_x2,
        status: t('Sales')
      },
      {
        name: t('Aleksandr Martynov'),
        img: Martynov,
        img2: Martynov_x2,
        status: t('Presale Manager')
      }
    ]
  };
}
