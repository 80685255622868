import * as React from 'react';
import { Component } from 'react';
import { withI18n } from 'react-i18next';
import getEvents from './events';

import {
  RoadMapBlock,
  Back,
  RoadMapWrapper,
  EventsList,
  RoadMapEvent,
  Date,
  Event,
  EventDesc,
  Image,
  EventLink
} from './styles';

class RoadMap extends Component {
  render() {
    const { t } = this.props;
    const events = getEvents(t);

    return (
      <RoadMapBlock>
        <Back first>
          <RoadMapWrapper>
            <h3>{t('UTEX Exchange Roadmap')}</h3>
            <EventsList>{events.utex.map(this.renderEvent)}</EventsList>
          </RoadMapWrapper>
        </Back>
        <Back>
          <RoadMapWrapper>
            <h3>{t('UT Investment Roadmap')}</h3>
            <EventsList>{events.investment.map(this.renderEvent)}</EventsList>
          </RoadMapWrapper>
        </Back>
      </RoadMapBlock>
    );
  }

  renderEvent({ date, desc, passed, successful, img, alignStart, smаllImg, link, linkAddress }) {
    return (
      <RoadMapEvent key={desc}>
        <Date>{date}</Date>
        <Event passed={passed} successful={successful}>
          <EventDesc alignStart={alignStart}>
            {desc}
            {link && (
              <EventLink href={linkAddress} target="_blank" rel="noopener noreferrer">
                {link}
              </EventLink>
            )}
            {img && <Image smаllImg={smаllImg} src={img} alt="" />}
          </EventDesc>
        </Event>
      </RoadMapEvent>
    );
  }
}

export default withI18n()(RoadMap);
