import styled from 'react-emotion';

export const AboutBlock = styled('section')`
  padding: 4.8rem 0 3rem;
  margin-bottom: 3rem;
  background-color: #f5f5f5;

  @media (max-width: 1660px) and (min-width: 1200px) {
    margin: 0 3rem 3rem 3rem;
  }

  @media (max-width: 1200px) {
    margin-bottom: 2rem;
  }

  @media (max-width: 768px) {
    padding: 2.7rem 1.6rem 0;
    margin: 0 0 1rem;
    width: 100%;
  }

  h2 {
    margin: 0 auto 5.8rem;
    max-width: 120rem;
    font-family: 'Dewi', Verdana, sans-serif;
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 4rem;

    @media (max-width: 768px) {
      margin: 0 auto 3rem;
      font-size: 2.4rem;
      text-align: center;
    }
  }
`;

export const AboutImages = styled('div')`
  margin: 0 auto;
  max-width: 120rem;
`;

export const ImageBlock = styled('div')`
  display: inline-block;
  margin: 0 0 7rem;

  @media (max-width: 768px) {
    margin-bottom: 3.3rem;
  }

  img {
    display: block;
    max-width: 23.3rem;
    width: 20rem;

    @media (max-width: 885px) {
      width: 17rem;
    }

    @media (max-width: 768px) {
      width: 17rem;
    }
  }
`;
