import React, { Component } from 'react';
import { withI18n } from 'react-i18next';

import hyperledger from './static/img/hyperledger.png';
import hyperledger_2 from './static/img/hyperledger@2x.png';
import element from './static/img/element.png';
import element_2 from './static/img/element@2x.png';
import mama from './static/img/mama.png';
import mama_2 from './static/img/mama@2x.png';
import rts from './static/img/rts.png';
import rts_2 from './static/img/rts@2x.png';
import icorating from './static/img/icorating.png';
import icorating_2 from './static/img/icorating@2x.png';

import { PartnersBlock, PartnerImages, Image } from './styles';

class Partners extends Component {
  render() {
    const { t } = this.props;

    return (
      <PartnersBlock>
        <h2>{t('Our Partners')}</h2>
        <PartnerImages>
          <div>
            <Image
              width={{ isHyperledger: true }}
              src={hyperledger}
              srcSet={`${hyperledger_2} 2x`}
              alt="Hyperledger"
            />
          </div>
          <div>
            <Image
              width={{ isElem: true }}
              src={element}
              srcSet={`${element_2} 2x`}
              alt="Element"
            />
          </div>
          <div>
            <Image width={{ isMama: true }} src={mama} srcSet={`${mama_2} 2x`} alt="Mama" />
          </div>
          <div>
            <Image width={{ isRTS: true }} src={rts} srcSet={`${rts_2} 2x`} alt="RTS" />
          </div>
          <div>
            <Image
              width={{ isIcorating: true }}
              src={icorating}
              srcSet={`${icorating_2} 2x`}
              alt="Icorating"
            />
          </div>
        </PartnerImages>
      </PartnersBlock>
    );
  }
}

export default withI18n()(Partners);
