import styled from 'react-emotion';

export const MainMenuBlock = styled('nav')`
  text-align: ${props => (props.header ? 'center' : 'left')};
  
  @media (max-width: 768px) {
    display: ${props => (props.header ? 'none' : 'block')};
  }
  
  ul {  
    display: flex;
    flex-direction: ${props => (props.header ? 'row' : 'column')};
    justify-content: space-between;
    margin: 0;
    padding: ${props => (props.header ? '0' : '0 0 0 2.4rem')};
  }

  li {
    margin: ${props => (props.header ? '0 3rem 0 0' : '0 0 0')};
    line-height: ${props => (props.header ? '2rem' : '2.2rem')};
    list-style: none;
    cursor: pointer;
    
    &:last-of-type {
      padding: ${props => (props.header ? '0' : '0 0 1rem')};
      border-bottom: ${props => (props.header ? '0' : '1px solid rgba(0, 0, 0, 0.1)}')};
  }

  a {
    display: inline-block;
    padding: ${props => (props.header ? '0' : '0 0 1.8rem')};
    width: 100%;
    color: #000;
    text-decoration: none;
  }
`;
